<template>
	<div>
		<div class="business-mobile" v-if="isMobile">
			<div class="banner posRelative">
				<div class="default-banner posAbsoluteAll" v-if="!banner.type"></div>
				<img :src="banner.url" class="posAbsoluteAll" draggable="false" v-if="banner.type===1">
				<video ref="bannerVideo" class="posAbsoluteAll video-div" :src="banner.url" :autoplay="true" loop="loop"
					:muted="isMuted" v-if="banner.type===2"></video>
				<div class="column-me title-text" :class="{'title-text-anim':showBannerAnim}" v-show="showBannerAnim">
					<span>{{banner.title}}</span>
					<span class="margin-top40">{{banner.sub_title}}</span>
				</div>
				<div v-if="banner.type===2">
					<img src="../assets/img/laba2.png" class="img-laba anim-bigger-small" v-if="isMuted"
						@click.stop="isMuted=false">
					<img src="../assets/img/laba1.png" class="img-laba anim-bigger-small" v-else
						@click.stop="isMuted=true">
				</div>
			</div>
			<div class="main-content" v-if="detailItem">
				<div class="title-text" v-if="language==='0'">业务介绍</div>
				<div class="title-text" v-if="language==='1'">Business Introduction</div>
				<div class="title-line"></div>
				<div class="introduce" v-html="detailItem.introduce">
				</div>
				<div class="margin-top50">
					<div class="item-div" v-for="(item,index) in detailItem.list" :key="index">
						<div class="width100Per height100Per posRelative">
							<img :src="item.img" class="img" :class="{'img-pos1':index%2===0,'img-pos2':index%2!==0}">
							<div class="card-div" :class="{'card-pos1':index%2===0,'card-pos2':index%2!==0}">
								<div class="main-div">
									<div class="title-text">{{item.business_name}}</div>
									<div class="title-line"></div>
									<div class="title margin-top40" v-html="item.business_introduce">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="business" v-else>
			<div class="banner posRelative">
				<div class="default-banner posAbsoluteAll" v-if="!banner.type"></div>
				<img :src="banner.url" class="posAbsoluteAll" draggable="false" v-if="banner.type===1">
				<video ref="bannerVideo" class="posAbsoluteAll video-div" :src="banner.url" :autoplay="true" loop="loop"
					:muted="isMuted" v-if="banner.type===2"></video>
				<div class="column-me title-text" :class="{'title-text-anim':showBannerAnim}" v-show="showBannerAnim">
					<span>{{banner.title}}</span>
					<span class="margin-top40">{{banner.sub_title}}</span>
				</div>
				<div v-if="banner.type===2">
					<img src="../assets/img/laba2.png" class="img-laba anim-bigger-small" v-if="isMuted"
						@click.stop="isMuted=false">
					<img src="../assets/img/laba1.png" class="img-laba anim-bigger-small" v-else
						@click.stop="isMuted=true">
				</div>
			</div>
			<div class="main-content" v-if="detailItem">
				<div class="title-text" v-if="language==='0'">业务介绍</div>
				<div class="title-text" v-if="language==='1'">Business Introduction</div>
				<div class="title-line"></div>
				<div class="introduce" v-html="detailItem.introduce">
				</div>
				<div class="margin-top100">
					<div class="item-div" v-for="(item,index) in detailItem.list" :key="index">
						<div :ref="'item'+index" class="width100Per height100Per posRelative"
							:class="{'bottom-to-top-anim':item.showAnim}">
							<img :src="item.img" class="img" :class="{'img-pos1':index%2===0,'img-pos2':index%2!==0}">
							<div class="card-div" :class="{'card-pos1':index%2===0,'card-pos2':index%2!==0}">
								<div class="main-div">
									<div class="title-text">{{item.business_name}}</div>
									<div class="title-line"></div>
									<div class="title margin-top40" v-html="item.business_introduce">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				banner: {},
				showBannerAnim: false,
				detailItem: null,
				language: '', //0中文，1英文
				isMuted: true, //视频是否静音
				isMobile: '', //0电脑，1手机
			}
		},
		watch: {
			scrollTop: {
				handler: function(newVal, oldVal) {
					if (this.detailItem) {
						for (var i = 0; i < this.detailItem.list.length; i++) {
							let item = this.detailItem.list[i]
							if (!this.$method.isElementNotInViewport(this.$refs['item' + i])) {
								item.showAnim = true
							}
						}
					}
				}
			},
			navItem: {
				handler: function(newVal, oldVal) {
					this.getBanner()
					this.getData()
				}
			}
		},
		computed: {
			scrollTop() {
				return this.$store.state.scrollTop;
			},
			navItem() {
				return this.$store.state.navItem;
			},
		},
		created() {
			this.isMobile = sessionStorage.getItem("mobile") === '1'
			this.language = localStorage.getItem("language")
			document.title = this.navItem.name
			setTimeout(() => {
				this.showBannerAnim = true
			}, 1000)
			this.getBanner()
			this.getData()
		},
		activated() {
			this.language = localStorage.getItem("language")
			document.title = this.navItem.name
			this.showBannerAnim = false
			setTimeout(() => {
				this.showBannerAnim = true
			}, 1000)
		},
		mounted() {

		},
		methods: {
			getBanner() {
				this.banner = {}
				let link_url = this.$store.state.navItem.link_url
				if (link_url == '/business') {
					this.$postRequest('/web/navInfo', {
						id: this.$store.state.navItem.id
					}).then((res) => {
						if (res) {
							this.banner = res.data
						}
					}).catch((error) => {
						console.log(error)
					})
				}
			},
			//获取数据
			getData() {
				this.$postRequest('/web/business', {}).then((res) => {
					if (res) {
						this.detailItem = res.data
						for (var i = 0; i < this.detailItem.list.length; i++) {
							this.detailItem.list[i].showAnim = false
						}
					}
				}).catch((error) => {
					console.log(error)
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.business-mobile {
		.banner {
			width: 100%;
			height: 35vh;

			.video-div {
				object-fit: cover;
			}

			.title-text {
				font-size: 25px;
				color: #FFFFFF;
				position: absolute;
				left: 20px;
				top: 30%;
				-ms-user-select: none;
				-webkit-user-select: none;
			}
		}

		.main-content {
			padding: 20px;

			.introduce {
				font-size: 18px;
				color: #000000;
				line-height: 32px;
				margin-top: 25px;
			}

			.item-div {
				width: 100%;
				margin-bottom: 50px;

				.img {
					width: 100%;
					height: 200px;
					object-fit: cover;
				}

				.img-pos1 {
					left: 0;
					top: 0;
				}

				.img-pos2 {
					right: 0;
					top: 0;
				}

				.card-div {
					width: 100%;
					background: #FFFFFF;
					box-shadow: 0px 10px 50px rgba(11, 15, 15, 0.1);
					z-index: 10;
				}

				.card-pos1 {
					right: 0;
					top: 200px;
				}

				.card-pos2 {
					left: 0;
					top: 200px;
				}

				.main-div {
					margin: 0 20px;
					padding: 20px 0;
					overflow: auto;

					.title {
						font-size: 18px;
						color: #333333;
						line-height: 32px;
					}
				}
			}
		}

	}

	.business {
		.banner {
			width: 100%;
			height: 80vh;

			.video-div {
				object-fit: cover;
			}

			.title-text {
				font-size: 80px;
				color: #FFFFFF;
				position: absolute;
				left: 278px;
				top: 40%;
				-ms-user-select: none;
				-webkit-user-select: none;
			}
		}

		.main-content {
			padding: 100px 278px;

			.introduce {
				font-size: 18px;
				color: #000000;
				line-height: 32px;
				margin-top: 25px;
			}

			.item-div {
				margin-bottom: 150px;
				width: 100%;
				height: 546px;

				.img {
					width: 800px;
					height: 466px;
					object-fit: cover;
					position: absolute;
				}

				.img-pos1 {
					left: 0;
					top: 0;
				}

				.img-pos2 {
					right: 0;
					top: 0;
				}

				.card-div {
					width: 800px;
					height: 466px;
					background: #FFFFFF;
					box-shadow: 0px 10px 50px rgba(11, 15, 15, 0.1);
					position: absolute;
					z-index: 10;
				}

				.card-pos1 {
					right: 0;
					bottom: 0;
				}

				.card-pos2 {
					left: 0;
					bottom: 0;
				}

				.main-div {
					height: 360px;
					margin: 60px 50px;
					overflow: auto;

					.title {
						font-size: 18px;
						color: #333333;
						line-height: 32px;
					}
				}
			}
		}

	}
</style>
